import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BlogListHome from "../components/blog-list-home";
import SEO from "../components/seo";
import Lightbox from "../components/lightbox";

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 1980
              quality: 80
              srcSetBreakpoints: [350, 700, 1050, 1400]
            ) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`;

const HomePage = ({ data }) => {
  const [lightboxState, setLightboxState] = useState({
    isOpen: false,
    photoSrc: "",
  });
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark;
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : "";
  const images = [{
      src: Image.src,
      srcThumbnail: Image.srcSet
        ? Image.srcSet.split(",")[0].split(" ")[0]
        : Image.src,
      title: Image.title,
  }];

  function handleFeaturedImageClick(event) {
    const target = event.target;
    console.log('image click', target, target.tagName);
    if (target.tagName === "IMG") {
      presentLightbox(target.src);
    }
  }

  function presentLightbox(src) {
    if (src) {
      setLightboxState({ isOpen: true, photoSrc: new URL(src).pathname });
    }
  }

  function handleLightboxClose() {
    setLightboxState({ isOpen: false, photoSrc: "" });
  }

  return (
    <Layout className="home-page">
      {images.length > 0 && (
        <Lightbox
          images={images}
          photoSrc={lightboxState.photoSrc}
          isOpen={lightboxState.isOpen}
          onClose={() => handleLightboxClose()}
        />
      )}
      <SEO />
      <div className="home-banner">
        <div className="home-banner--inner" role="presentation" onClick={(e) => handleFeaturedImageClick(e)}>
          {Image ? (
            <Img
              fluid={Image}
              alt={frontmatter.title + " - Featured image"}
              className="featured-image"
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <BlogListHome />
    </Layout>
  );
};

export default HomePage;
