import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri";

import PostCard from "./post-card";

interface PostMakerProps {
  data: any;
}

const PostMaker = ({ data }: PostMakerProps) => (
  <section className="home-posts">
    <h2 className="home-posts__title">
      Latest News{" "}
      <span className="icon -right">
        <RiArrowDownLine />
      </span>
    </h2>
    <div className="grids">{data}</div>
    <Link className="home-posts__more" to="/blog">
      See more
      <span className="icon -right">
        <RiArrowRightSLine />
      </span>
    </Link>
  </section>
);

export default function BlogListHome() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { template: { eq: "blog-post" } } }
            limit: 6
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  slug
                  title
                  featuredImage {
                    childImageSharp {
                      fluid(
                        maxWidth: 1980
                        quality: 80
                        srcSetBreakpoints: [350, 700, 1050, 1400]
                      ) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allMarkdownRemark.edges
          .filter(edge => !!edge.node.frontmatter.date)
          .map(edge => <PostCard key={edge.node.id} data={edge.node} />);
        return <PostMaker data={posts} />;
      }}
    />
  );
}
